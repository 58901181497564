@font-face {
  font-family: "Roobert";
  font-weight: 300;
  font-display: fallback;
  src: url("../fonts/Roobert-Light.woff2"), url("../fonts/Roobert-Light.woff"),
    url("../fonts/Roobert-Light.eot");
}

@font-face {
  font-family: "Roobert";
  font-weight: 400;
  font-display: fallback;
  src: url("../fonts/Roobert-Regular.woff2"),
    url("../fonts/Roobert-Regular.woff"), url("../fonts/Roobert-Regular.eot");
}

@font-face {
  font-family: "Roobert";
  font-weight: 500;
  font-display: fallback;
  src: url("../fonts/Roobert-Medium.woff2"), url("../fonts/Roobert-Medium.woff"),
    url("../fonts/Roobert-Medium.eot");
}

@font-face {
  font-family: "Roobert";
  font-weight: 700;
  font-display: fallback;
  src: url("../fonts/Roobert-Bold.woff2"), url("../fonts/Roobert-Bold.woff"),
    url("../fonts/Roobert-Bold.eot");
}

@font-face {
  font-family: "Roobert";
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
  src: url("../fonts/Roobert-LightItalic.woff2"),
    url("../fonts/Roobert-LightItalic.woff"),
    url("../fonts/Roobert-LightItalic.eot");
}

@font-face {
  font-family: "Roobert";
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
  src: url("../fonts/Roobert-RegularItalic.woff2"),
    url("../fonts/Roobert-RegularItalic.woff"),
    url("../fonts/Roobert-RegularItalic.eot");
}

@font-face {
  font-family: "Roobert";
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
  src: url("../fonts/Roobert-BoldItalic.woff2"),
    url("../fonts/Roobert-BoldItalic.woff"),
    url("../fonts/Roobert-BoldItalic.eot");
}
